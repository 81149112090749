import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Cart } from "./cart";

import { useCart } from "../context/cart-context";
import { useSettings } from "../hooks/useSettings";

const Header = styled.header`
  display: flex;
  background: #333;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

const NavList = styled.ul`
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CartContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CartBadge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  min-width: 16px;
  text-align: center;
`;

const SiteTitle = styled.span`
  color: white;
  font-size: 1.5rem;
  width: 100%;
`

const PageHeader = () => {
  const { title, enableCart } = useSettings()
  const cartContext  = useCart()
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  // Get the total items in the cart
  const totalItems = cartContext.state.items.reduce((total, item) => {
    return total + item.quantity;
  }, 0)

  return (
    <Header>
    <SiteTitle>{title}</SiteTitle>
    <Nav>
      <NavList>
        
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/books">Books</NavLink>
        </li>
        <li>
          <NavLink to="/blog">Blog</NavLink>
        </li>
        {enableCart && <li>
          <NavLink to="#" onClick={(e) => {
              e.preventDefault();
              toggleCart();
            }}>
            <CartContainer>
              🛒
              {totalItems > 0 && (
                <CartBadge>{totalItems}</CartBadge>
              )}
            </CartContainer>
          </NavLink>
        </li>}
      </NavList>
    </Nav>
    <Cart 
        isopen={isCartOpen} 
        onClose={() => setIsCartOpen(false)} 
      />
    </Header>
  );
};

export default PageHeader;
