import React from 'react'
import {Breadcrumb} from 'gatsby-plugin-breadcrumb'
import styled from 'styled-components';

const BreadcrumbWrapper = styled.div`
    width: 100%
`

interface BreadcrumbContainerProps {
    breadcrumbs: any
}

function toTitleCase(str: string): string {
    // First, replace hyphens with spaces and split into words
    const words = str.replace(/-/g, ' ').toLowerCase().split(' ');
    
    // Words that shouldn't be capitalized (unless first or last)
    const minorWords = new Set(['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'in', 'on', 'at', 'to', 'with', 'from']);
    
    return words
        .map((word, index) => {
            // Always capitalize first and last words
            if (index === 0 || index === words.length - 1) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            
            // Check if it's a minor word
            if (minorWords.has(word)) {
                return word;
            }
            
            // Capitalize first letter of other words
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

const formatCrumbs = (crumbs:any) => {
    return crumbs.map((crumb: any) => {
      return {
        ...crumb,
        crumbLabel: toTitleCase(crumb.crumbLabel.replace(/-/g, ' '))      
      }
    })
  }

export default function BreadcrumbContainer({ breadcrumbs }:BreadcrumbContainerProps) {
    if (breadcrumbs.crumbs.length <= 1) {
        return <></>
    }


    return (
        <BreadcrumbWrapper>
            <Breadcrumb crumbs={formatCrumbs(breadcrumbs.crumbs)} separator=" | "/>    
        </BreadcrumbWrapper>
    
    )
}