// src/hooks/useSettings.ts
import { useStaticQuery, graphql } from "gatsby"

export const useSettings = () => {
  const { settingsYaml } = useStaticQuery(graphql`
    query SettingsQuery {
      settingsYaml {
        title
        author
        theme {
          primaryColor
          secondaryColor
          fontFamily
        }
        social {
            instagram
            youtube
        }
      }
    }
  `)

  return settingsYaml
}
