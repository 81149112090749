import React, { createContext, useContext, useReducer, useEffect } from "react";

interface CartItem {
  id: string; // This will now be a composite ID
  bookId: string; // Original book ID
  title: string;
  format: string;
  price: number;
  quantity: number;
}

interface AddToCartData {
    bookId: string;
    title: string;
    format: string;
    price: number;
  }

// Helper function to create composite IDs
const createCartItemId = (bookId: string, format: string) => {
  return `${bookId}-${format}`;
};

interface CartState {
  items: CartItem[];
  total: number;
  totalItems?: number;
}

type CartAction =
  | { type: "ADD_ITEM"; payload: AddToCartData }
  | { type: "REMOVE_ITEM"; payload: { id: string; } }
  | { type: "UPDATE_QUANTITY"; payload: { id: string; quantity: number } }
  | { type: "CLEAR_CART" }
  | { type: "GET_TOTAL_ITEMS" };

const CartContext = createContext<
  | {
      state: CartState;
      dispatch: React.Dispatch<CartAction>;
    }
  | undefined
>(undefined);

const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    // Create the cart item ID here in the reducer

    case "ADD_ITEM": {
    const cartItemId = createCartItemId(action.payload?.bookId, action.payload.format);
      
    const existingItem = state.items.find(
      item => item.id === cartItemId
    )

    if (existingItem) {
      return {
        ...state,
        items: state.items.map(item =>
          item.id === cartItemId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ),
        total: state.total + action.payload.price
      }
    }

    // Create new cart item
    const newCartItem: CartItem = {
      id: cartItemId,
      bookId: action.payload.bookId,
      title: action.payload.title,
      format: action.payload.format,
      price: action.payload.price,
      quantity: 1
    };

    return {
      ...state,
      items: [...state.items, newCartItem],
      total: state.total + action.payload.price
    }
  }
  
      case 'REMOVE_ITEM': {
        const itemToRemove = state.items.find(
          item => item.id === action.payload.id
        );
        
        return {
          ...state,
          items: state.items.filter(
            item => item.id !== action.payload.id
          ),
          total: state.total - (itemToRemove ? itemToRemove.price * itemToRemove.quantity : 0)
        }
      }

    case "UPDATE_QUANTITY": {
      const item = state.items.find((item) => item.id === action.payload.id);
      if (!item) return state;

      const quantityDiff = action.payload.quantity - item.quantity;

      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
        total: state.total + item.price * quantityDiff,
      };
    }

    case "GET_TOTAL_ITEMS": {
      return {
        ...state,
        totalItems: state.items.reduce((acc, item) => acc + item.quantity, 0),
      };
    }

    case "CLEAR_CART":
      return {
        items: [],
        total: 0,
      };

    default:
      return state;
  }
};

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(cartReducer, { items: [], total: 0 });

  // Load cart from localStorage on mount
  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const { items, total } = JSON.parse(savedCart);
      dispatch({ type: "CLEAR_CART" });
      items.forEach((item: CartItem) => {
        dispatch({ type: "ADD_ITEM", payload: item });
      });
    }
  }, []);

  // Save cart to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state));
  }, [state]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
