import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { CartProvider } from "../context/cart-context";
import { GlobalStyle } from "../styles/global";
import PageHeader from "./header";
import { useSettings } from "../hooks/useSettings";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import BreadcrumbContainer from "./BreadcrumbContainer";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BreadcrumbWrapper = styled.div`
  background-color: white;
`;

const Main = styled.main`
  flex: 1;
  width: 100%;
  // max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const Footer = styled.footer`
  background: #333;
  color: white;
  padding: 1rem;
  text-align: center;
`;

interface LayoutProps {
  pageContext: any;
  children: React.ReactNode;
}

interface Breadcrumb {
  label: string;
  path: string;
}

const Layout: React.FC<LayoutProps> = ({ pageContext, children }) => {
  console.log(pageContext);

  const { breadcrumb } = pageContext;

  const { author } = useSettings();

  return (
    <CartProvider>
      <GlobalStyle />
      <Container>
        <PageHeader />
        { breadcrumb && <BreadcrumbWrapper>
          <BreadcrumbContainer breadcrumbs={breadcrumb} />
        </BreadcrumbWrapper>}

        <Main>{children}</Main>

        <Footer>
          <p>
            © {new Date().getFullYear()} {author}. All rights reserved.
          </p>
        </Footer>
      </Container>
    </CartProvider>
  );
};

export default Layout;
