import React from 'react'
import styled from 'styled-components'
import { useCart } from '../context/cart-context'
import { IoClose } from "react-icons/io5";
import { on } from 'events';

interface CartContainerProps {
    isopen: boolean
} 

const CartContainer = styled.div<CartContainerProps>`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transform: translateX(${props => props.isopen ? '0' : '100%'});
  transition: transform 0.3s ease;
  z-index: 1000;
`

const CartItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
`

const CartTotal = styled.div`
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.2rem;
`

const CheckoutButton = styled.button`
  width: 100%;
  padding: 1rem;
  background: #000;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    background: #333;
  }
`

interface CartProps {
  isopen: boolean
  onClose: () => void
}

const CloseButton = styled(IoClose)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
`

export const Cart: React.FC<CartProps> = ({ isopen, onClose }) => {
  const { state, dispatch } = useCart()

  const handleCheckout = async () => {
    // Implement Stripe checkout here
  }

  return (
    <CartContainer isopen={isopen}>
      <h2>Your Cart</h2>

    <CloseButton onClick={onClose}/>      
    {state.items.map(item => (
        <CartItem key={`${item.id}-${item.format}`}>
          <div>
            <h3>{item.title}</h3>
            <p>{item.format}</p>
            <p>${item.price}</p>
          </div>
          <div>
            <input
              type="number"
              min="1"
              value={item.quantity}
              onChange={(e) => 
                dispatch({
                  type: 'UPDATE_QUANTITY',
                  payload: { id: item.id, quantity: parseInt(e.target.value) }
                })
              }
            />
            <button
              onClick={() => 
                dispatch({ type: 'REMOVE_ITEM', payload: { id: item.id} })
              }
            >
              Remove
            </button>
          </div>
        </CartItem>
      ))}
      <CartTotal>Total: ${state.total.toFixed(2)}</CartTotal>
      <CheckoutButton onClick={handleCheckout}>
        Checkout
      </CheckoutButton>
    </CartContainer>
  )
}
